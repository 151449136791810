import { LoginRequest, LoggedAccount } from '../store/config/types/auth.types';
import axios from './axios';

async function login({
  email,
  password,
  device = 'admin',
  messagingToken = '',
  loginType = 'SHIPPER',
  userType = 'SHIPPER',
}: LoginRequest) {
  const res = await axios.post('/auth/login', { email, password, device, messagingToken, loginType, userType });
  return res.data;
}

async function logout() {
  const res = await axios.get('/auth/logout');
  return res.data;
}

async function getAccount(shipperId: number) {
  const res = await axios.get(`/shippers/${shipperId}`);
  return res.data;
}

async function updateAccount(shipperId: number, shipperData: LoggedAccount) {
  const res = await axios.patch(`/shippers/${shipperId}`, shipperData);
  return res.data;
}

export const authService = { login, logout, getAccount, updateAccount };

import classNames from 'classnames';
import { withStyles, AppBar, Toolbar } from '@material-ui/core';
import headerStyle from '../../assets/jss/headerStyle';
import HeaderLinks from './HeaderLinks/HeaderLinks';

const Header = ({ classes, color, ...props }) => {
  const appBarClasses = classNames({ [' ' + classes[color]]: color });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} />
        <HeaderLinks onLogoutClick={props.handleLogout} />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);

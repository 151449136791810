interface CardHomeProps {
  image: string;
  amount: number;
  title: string;
  color: string;
}

function CardHome({ image, amount, title, color }: CardHomeProps) {
  return (
    <div className="home-card">
      <div className={`card-icon ${color}`}>
        <img className="icon" src={image} alt="home" />
      </div>
      <div className="card-info">
        <p className="number-info">{amount}</p>
        <p className="text-info">{title}</p>
      </div>
    </div>
  );
}

export default CardHome;

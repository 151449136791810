import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { usePapaParse } from 'react-papaparse';
import { useFilePicker } from 'use-file-picker';
import { FileContent } from 'use-file-picker/dist/interfaces';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/loader.json';
import { RootState } from '../../store/config/types';
import { parseCSVToPackages } from '../../store/actions/deliveryOrders.actions';
import { setToast } from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SelectField from '../../components/SpecialInput/SelectField';

interface CSVValues {
  customerFirstName: string;
  customerLastName: string;
  customerAddressLineOne: string;
  customerAddressLineTwo: string;
  customerAddressLineThree: string;
  customerAddressUnit: string;
  numberOfPackages: string;
  largestPackageSize: string;
}

interface CSVDialogProps {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen(value: boolean): void;
}

const mapStateToProps = (state: RootState) => ({
  deliveryOrders: state.deliveryOrders,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  parseCSVToPackages: (formData: FormData) => dispatch(parseCSVToPackages(formData)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = CSVDialogProps & PropsFromRedux;

function CSVDialog({ open, setOpen, deliveryOrders, parseCSVToPackages, setToast }: PropsType) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [openFileSelector, { loading, filesContent, plainFiles }] = useFilePicker({ accept: '.csv', multiple: false });
  const { readString } = usePapaParse();

  const submitForm = (values: CSVValues) => {
    const formData: FormData = new FormData();

    formData.append('deliveryOrders', plainFiles[0]);
    formData.append('customerFirstName', values.customerFirstName);
    formData.append('customerLastName', values.customerLastName);
    formData.append('customerAddressLineOne', values.customerAddressLineOne);
    formData.append('customerAddressLineTwo', values.customerAddressLineTwo);
    formData.append('customerAddressLineThree', values.customerAddressLineThree);
    formData.append('customerAddressUnit', values.customerAddressUnit);
    formData.append('numberOfPackages', values.numberOfPackages);
    formData.append('largestPackageSize', values.largestPackageSize);

    setSubmitting(true);
    parseCSVToPackages(formData);
  };

  useEffect(() => {
    if (loading) {
      setLoaded(false);
    }
  }, [loading, setLoaded]);

  useEffect(() => {
    if (submitting && deliveryOrders.parseCSVErrorMessage !== null) {
      setToast('There was an error parsing CSV', 'danger');
    }
  }, [submitting, deliveryOrders.parseCSVErrorMessage, setToast]);

  useEffect(() => {
    if (filesContent && filesContent.length !== 0 && !loaded) {
      readString(filesContent[0].content, {
        header: true,
        worker: true,
        complete: (results) => {
          setHeaders(results.meta.fields!);
        },
      });
      setLoaded(true);
    }
  }, [filesContent, loaded, setLoaded, readString]);

  useEffect(() => {
    if (submitting && deliveryOrders.parseCSVSuccess && deliveryOrders.parsedCSVLines !== null) {
      setOpen(false);
    }
  }, [submitting, deliveryOrders.parseCSVSuccess, deliveryOrders.parsedCSVLines, setOpen]);

  const headerValues = ['None', ...headers].map((size: string) => (
    <MenuItem key={size} value={size !== 'None' ? size : ''}>
      {size}
    </MenuItem>
  ));

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Formik
          initialValues={{
            customerFirstName: '',
            customerLastName: '',
            customerAddressLineOne: '',
            customerAddressLineTwo: '',
            customerAddressLineThree: '',
            customerAddressUnit: '',
            numberOfPackages: '',
            largestPackageSize: '',
          }}
          validationSchema={schemas.CSVSchema}
          onSubmit={submitForm}
        >
          <Form>
            <div className="csv-dialog">
              <div className="import">
                <Button onClick={openFileSelector}>Select files</Button>
                {filesContent.map((file: FileContent, index: number) => (
                  <div key={index}>{file.name}</div>
                ))}
              </div>
              <div className="dialog-texts">
                <p>We need to locate the following information in your CSV.</p>
                <p>What´s the name of the column that has each of these data?</p>
              </div>
              <GridContainer>
                {deliveryOrders.parsingCSV ? (
                  <div className="loading">
                    <Lottie animationData={loadingAnimation} loop={true} />
                  </div>
                ) : (
                  <>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer first name"
                        name="customerFirstName"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer last name"
                        name="customerLastName"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer address line 1"
                        name="customerAddressLineOne"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer address line 2 (opt)"
                        name="customerAddressLineTwo"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer address line 3 (opt)"
                        name="customerAddressLineThree"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <SelectField
                        placeholder="Customer address unit (opt)"
                        name="customerAddressUnit"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <SelectField
                        placeholder="Count of packages"
                        name="numberOfPackages"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <SelectField
                        placeholder="Largest package"
                        name="largestPackageSize"
                        values={headerValues}
                        disabled={!loaded}
                      />
                    </GridItem>
                  </>
                )}
                <div className="import-button">
                  <Button color="primary" type="submit" disabled={deliveryOrders.parsingCSV}>
                    Start import
                  </Button>
                </div>
              </GridContainer>
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default connector(CSVDialog);

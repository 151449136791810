import { useState } from 'react';
import { Dialog, MenuItem } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { ThunkDispatch } from 'redux-thunk';
import { connect, ConnectedProps } from 'react-redux';
import { DialogContent } from '@mui/material';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import SelectField from '../../components/SpecialInput/SelectField';
import AutocompleteField from '../../components/SpecialInput/AutocompleteField';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import schemas from '../../utils/schemas';
import useIsMobile from '../../utils/useIsMobile';
import { CSVLine, PackageModel } from '../../store/config/types/deliveryOrders.types';
import { editCSVLine } from '../../store/actions/deliveryOrders.actions';
import { Customer, CustomersState, RootState } from '../../store/config/types';
import { Coordinates } from '../../store/config/types/location.types';
import CustomersForm from '../Customers/CustomersForm';

export interface PackageValues {
  customerId: number;
  packagesAmount: number;
  packagesSize: string;
  deliveryInstructions: string;
  customSize: string;
  firstName: string;
  lastName: string;
}

interface PackageFormProps {
  customers: CustomersState;
  // eslint-disable-next-line no-unused-vars
  submitPackage(values: PackageValues, formikHelpers: FormikHelpers<PackageValues>): void;
  selectedPackage?: PackageModel;
  closeDialog?(): void;
}

const sizes: string[] = ['Small', 'Medium', 'Large', 'Custom'];

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  editCSVLine: (csvLine: CSVLine) => dispatch(editCSVLine(csvLine)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PackageFormProps & PropsFromRedux;

function PackageForm({ customers, submitPackage, selectedPackage, closeDialog, editCSVLine }: PropsType) {
  const isMobile = useIsMobile();
  const isEditing = !!selectedPackage;

  const [address, setAddress] = useState<string>((selectedPackage && selectedPackage.streetAddress) || '');
  const [coordinates, setCoordinates] = useState<Coordinates>({ lat: null, lng: null });
  const [city, setCity] = useState<string>('');
  const [customerOpen, setCustomerOpen] = useState<boolean>(false);
  const [createdCustomer, setCreatedCustomer] = useState<Customer | null>(null);

  const createNewCustomer = () => setCustomerOpen(true);

  const isAvailableSize = (size: string) => sizes.indexOf(size) > -1;

  const editPackage = (values: PackageValues, formikHelpers: FormikHelpers<PackageValues>) => {
    if (values.customerId) {
      // Is manually added package
      submitPackage(values, formikHelpers);
    } else {
      // Is CSV package
      const csvLine: CSVLine = {
        city: city || selectedPackage?.city!,
        latitude: coordinates.lat || selectedPackage?.latitude || 0,
        longitude: coordinates.lng || selectedPackage?.longitude || 0,
        count: values.packagesAmount.toString(),
        size: values.packagesSize !== 'Custom' ? values.packagesSize : values.customSize,
        firstName: values.firstName,
        lastName: values.lastName,
        streetAddress: address,
        packageId: selectedPackage?.packageId?.toString() || '0',
        deliveryInstructions: values.deliveryInstructions,
      };

      editCSVLine(csvLine);
      closeDialog && closeDialog();
    }
  };

  const closeCustomerModal = () => setCustomerOpen(false);

  const customerModalSuccess = (customer: Customer) => {
    setCreatedCustomer(customer);
    closeCustomerModal();
  };

  return (
    <>
      <Formik
        initialValues={{
          customerId: createdCustomer ? createdCustomer.customerId : selectedPackage ? selectedPackage.customerId! : 0,
          packagesAmount: selectedPackage ? selectedPackage.packagesAmount! : 0,
          packagesSize: selectedPackage
            ? isAvailableSize(selectedPackage.packagesSize!)
              ? selectedPackage.packagesSize!
              : 'Custom'
            : 'Small',
          deliveryInstructions: selectedPackage ? selectedPackage.deliveryInstructions! : '',
          customSize: selectedPackage
            ? isAvailableSize(selectedPackage.packagesSize!)
              ? selectedPackage.customSize!
              : selectedPackage.packagesSize!
            : '',
          firstName: selectedPackage && selectedPackage.firstName ? selectedPackage.firstName : '',
          lastName: selectedPackage && selectedPackage.lastName ? selectedPackage.lastName : '',
        }}
        validationSchema={schemas.PackageSchema}
        onSubmit={editPackage}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <GridContainer noMarginTop>
              <GridItem xs={12} md={8}>
                {customers.customers && (!selectedPackage || selectedPackage?.customerId) && (
                  <div className="customer-row">
                    <AutocompleteField
                      placeholder="Customer"
                      name="customerId"
                      values={customers.customers.map((customer: Customer) => ({
                        id: customer.customerId,
                        title: `${customer.firstName || ''} ${customer.lastName || ''} ${
                          customer.location?.streetAddress ? '-' : ''
                        } ${customer.location?.streetAddress || ''}`,
                      }))}
                      value={values.customerId}
                    />
                    {!isEditing && (
                      <span className="link" onClick={createNewCustomer}>
                        {isMobile ? 'New' : 'Create new customer'}
                      </span>
                    )}
                  </div>
                )}
              </GridItem>
              {!isMobile && <GridItem md={4} />}
              {isEditing && !selectedPackage?.customerId && (
                <>
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="First name" name="firstName" />
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="Last name" name="lastName" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputSize={4}
                  />
                </>
              )}
              <GridItem xs={6} md={3}>
                <InputField placeholder="Number of packages" name="packagesAmount" type="number" />
              </GridItem>
              <GridItem xs={6} md={3}>
                <SelectField
                  placeholder="Size of the largest package"
                  name="packagesSize"
                  leftAligned
                  values={sizes.map((size: string) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              <GridItem xs={12} md={selectedPackage ? 3 : 2}>
                {values.packagesSize === 'Custom' && <InputField placeholder="Custom size" name="customSize" />}
              </GridItem>
              <GridItem xs={12}>
                <InputField
                  placeholder="Delivery instructions"
                  name="deliveryInstructions"
                  type="textarea"
                  multiline={true}
                  rows={3}
                  formControlClassName="package-custom-input"
                />
              </GridItem>
              <GridItem xs={12} className="left-align">
                <Button type="submit" color="primary">
                  {isEditing ? 'Save changes' : 'Add delivery item'}
                </Button>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
      <Dialog open={customerOpen} onClose={closeCustomerModal}>
        <DialogContent>
          <CustomersForm fromModal closeModal={closeCustomerModal} submitModal={customerModalSuccess} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connector(PackageForm);

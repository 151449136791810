import { Courier, CreateCourierRequest, Vehicle } from '../store/config/types';
import axios from './axios';

async function fetchCouriers(): Promise<Courier[] | undefined> {
  const res = await axios.get('/couriers');
  return res.data.data;
}

async function createCourier(
  createCourierRequest: CreateCourierRequest,
  courierPhoto?: File,
): Promise<Courier | undefined> {
  const res = await axios.post('/couriers/register', createCourierRequest);
  if (res.data.errorCode === 0 && courierPhoto) {
    const createdCourier = res.data.data;
    const formData: FormData = new FormData();
    formData.append('photo', courierPhoto);
    const photoRes = await axios.patch(`/couriers/${createdCourier.courierId}/photo`, formData);
    if (photoRes.data.errorCode !== 0) {
      return res.data;
    }
  }
  return res.data.data;
}

async function updateCourier(
  courierId: number,
  updateCourierRequest: CreateCourierRequest,
  courierPhoto?: File,
): Promise<Courier | undefined> {
  const res = await axios.patch(`/couriers/${courierId}`, updateCourierRequest);
  if (res.data.errorCode === 0 && courierPhoto) {
    const formData: FormData = new FormData();
    formData.append('photo', courierPhoto);
    const photoRes = await axios.patch(`/couriers/${courierId}/photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (photoRes.data.errorCode !== 0) {
      return res.data;
    }
  }
  return res.data;
}

async function deleteCourier(courierId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/couriers/${courierId}`);
  return res.data.data;
}

async function addVehicleToCourier(vehicle: Vehicle): Promise<Vehicle | undefined> {
  const res = await axios.post(`/couriers/${vehicle.courierId}/vehicle`, vehicle);
  return res.data.data;
}

async function updateCourierVehicle(vehicle: Vehicle): Promise<any> {
  const res = await axios.patch(`/couriers/${vehicle.courierId}/vehicle/${vehicle.id}`, vehicle);
  return res.data.data;
}

async function deleteCourierVehicle(courierId: string, vehicleId: string): Promise<any> {
  const res = await axios.delete(`/couriers/${courierId}/vehicle/${vehicleId}`);
  return res.data.data;
}

export const couriersService = {
  fetchCouriers,
  createCourier,
  updateCourier,
  deleteCourier,
  addVehicleToCourier,
  updateCourierVehicle,
  deleteCourierVehicle,
};

import { ChangeEvent } from 'react';
import { TextField, FormControl } from '@material-ui/core';

interface DatePickerProps {
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function DatePicker({ label, value, onChange }: DatePickerProps) {
  return (
    <FormControl fullWidth>
      <TextField
        type="date"
        label={label}
        name={label}
        value={value}
        onChange={onChange}
        variant="outlined"
        margin="dense"
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
}

export default DatePicker;

import { useEffect, useState, Fragment, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { RootState } from '../../store/config/types';
import { ExtendedDeliveryOrder } from '../../store/config/types/deliveryOrders.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import AddButton from '../../components/CustomButtons/AddButton';
import DatePicker from '../../components/DatePicker/DatePicker';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SpecialInput from '../../components/SpecialInput/Input';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    deliveryOrders: state.deliveryOrders,
    warehouses: state.warehouses,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrders({
  auth,
  deliveryOrders,
  warehouses,
  loggedIn,
  fetchDeliveryOrders,
  fetchWarehouses,
}: PropsFromRedux) {
  const history: History = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  useEffect(() => {
    if (
      !deliveryOrders.loadingDeliveryOrders &&
      !deliveryOrders.deliveryOrders &&
      !deliveryOrders.deliveryOrdersErrorMessage
    ) {
      fetchDeliveryOrders();
    }
  }, [
    fetchDeliveryOrders,
    deliveryOrders.deliveryOrders,
    deliveryOrders.deliveryOrdersErrorMessage,
    deliveryOrders.loadingDeliveryOrders,
    loggedIn,
  ]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage &&
      auth.account
    ) {
      fetchWarehouses(auth.account?.shipperId || 0);
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
    auth.account,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateDeliveryOrder = () => history.push('/delivery-orders/create');

  const handleUpdateDeliveryOrder = (deliveryOrderId: string) =>
    history.push(`/delivery-orders/${deliveryOrderId}/detail`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const handleDateRange = (e: ChangeEvent<HTMLInputElement>) =>
    e.target.name === 'Date from' ? setDateFrom(e.target.value) : setDateTo(e.target.value);

  const filters = (
    <GridContainer>
      <GridItem xs={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <DatePicker label="Date from" value={dateFrom} onChange={handleDateRange} />
      </GridItem>
      <GridItem xs={12} md={3}>
        <DatePicker label="Date until" value={dateTo} onChange={handleDateRange} />
      </GridItem>
    </GridContainer>
  );

  let deliveryOrdersList = null;

  if (deliveryOrders.deliveryOrders) {
    let deliveryOrdersToShow: ExtendedDeliveryOrder[] = deliveryOrders.deliveryOrders || [];

    deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: ExtendedDeliveryOrder) => {
      const fromDate = moment(dateFrom);
      const toDate = moment(dateTo);
      const deliveryOrderTime = moment(deliveryOrder.createdAt);

      if (dateFrom && dateTo && fromDate <= toDate) {
        return deliveryOrderTime.isBetween(fromDate, toDate, 'days', '[]');
      }
      return deliveryOrder !== null;
    });

    if (searchText !== '') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find((w: Warehouse) => w.warehouseId === deliveryOrder.warehouseId);
        if (warehouse?.name?.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }

        if (
          moment
            .utc(deliveryOrder.deliveryExpectedAt)
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return true;
        }

        return ['packagesAmount', 'numberOfRecipients'].some((key) => {
          return (deliveryOrder as any)[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = deliveryOrdersToShow
      ?.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find((w: Warehouse) => w.warehouseId === deliveryOrder.warehouseId);

        return [
          deliveryOrder.deliveryOrderId,
          warehouse?.name,
          moment.utc(deliveryOrder.deliveryExpectedAt).format('MM/DD/YYYY'),
          deliveryOrder.packagesAmount,
          deliveryOrder.numberOfRecipients,
        ];
      });

    deliveryOrdersList = (
      <TableList
        title="Delivery Orders"
        head={['Id', 'Warehouse', 'Expected date', 'Number of packages', 'Number of recipients', '']}
        onShowDetail={handleUpdateDeliveryOrder}
        detail
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={deliveryOrdersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (deliveryOrders.loadingDeliveryOrders) {
    deliveryOrdersList = <Spinner />;
  } else {
    deliveryOrdersList = <div>No data to show</div>;
  }

  if (!deliveryOrders.deliveryOrdersFetched) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {deliveryOrdersList}
      <AddButton onClick={handleCreateDeliveryOrder} />
    </Fragment>
  );
}

export default connector(DeliveryOrders);

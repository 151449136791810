import { Courier, CouriersState } from '../config/types';
import {
  CouriersActionTypes,
  FETCHING_COURIERS,
  FETCH_COURIERS_SUCCESS,
  FETCH_COURIERS_FAILED,
  DELETING_COURIER,
  DELETE_COURIER_SUCCESS,
  DELETE_COURIER_FAILED,
  CREATING_COURIER,
  CREATE_COURIER_SUCCESS,
  CREATE_COURIER_FAILED,
  UPDATING_COURIER,
  UPDATE_COURIER_SUCCESS,
  UPDATE_COURIER_FAILED,
  ADDING_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILED,
  SET_COURIER,
  UNSET_COURIER,
  UPDATING_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILED,
  DELETING_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILED,
  CLEAR_COURIERS_STATE,
} from '../config/ActionTypes';

export const initialState: CouriersState = {
  loadingCouriers: false,
  couriersFetched: false,
  couriers: null,
  courier: null,
  couriersErrorMessage: null,
  creatingCourier: false,
  createCourierSuccess: false,
  createCourierErrorMessage: null,
  updatingCourier: false,
  updateCourierSuccess: false,
  updateCourierErrorMessage: null,
  deletingCourier: false,
  deleteCourierSuccess: false,
  deleteCourierErrorMessage: null,
  addingVehicle: false,
  addVehicleSuccess: false,
  addVehicleErrorMessage: null,
  updatingVehicle: false,
  updateVehicleSuccess: false,
  updateVehicleErrorMessage: null,
  deletingVehicle: false,
  deleteVehicleSuccess: false,
  deleteVehicleErrorMessage: null,
};

export function couriersReducer(state = initialState, action: CouriersActionTypes): CouriersState {
  switch (action.type) {
    case FETCHING_COURIERS:
      return {
        ...state,
        loadingCouriers: true,
        couriers: null,
        couriersErrorMessage: null,
      };

    case FETCH_COURIERS_SUCCESS:
      return {
        ...state,
        loadingCouriers: false,
        couriersFetched: true,
        couriers: action.couriers,
        couriersErrorMessage: null,
      };

    case FETCH_COURIERS_FAILED:
      return {
        ...state,
        loadingCouriers: false,
        couriersFetched: true,
        couriers: null,
        couriersErrorMessage: action.error,
      };

    case SET_COURIER:
      const newCourier = state.couriers?.find((p) => p.courierId === Number(action.courierId)) || null;
      return {
        ...state,
        courier: newCourier,
      };

    case UNSET_COURIER:
      return {
        ...state,
        courier: null,
      };

    case CREATING_COURIER:
      return {
        ...state,
        creatingCourier: true,
        createCourierSuccess: false,
        createCourierErrorMessage: null,
      };

    case CREATE_COURIER_SUCCESS:
      const newCouriers = state.couriers ? [...state.couriers] : [];
      newCouriers.push(action.courier);

      return {
        ...state,
        couriers: newCouriers,
        creatingCourier: false,
        createCourierSuccess: true,
        createCourierErrorMessage: null,
      };

    case CREATE_COURIER_FAILED:
      return {
        ...state,
        creatingCourier: false,
        createCourierSuccess: false,
        createCourierErrorMessage: action.error,
      };

    case UPDATING_COURIER:
      return {
        ...state,
        updatingCourier: true,
        updateCourierSuccess: false,
        updateCourierErrorMessage: null,
      };

    case UPDATE_COURIER_SUCCESS:
      return {
        ...state,
        updatingCourier: false,
        updateCourierSuccess: true,
        updateCourierErrorMessage: null,
      };

    case UPDATE_COURIER_FAILED:
      return {
        ...state,
        updatingCourier: false,
        updateCourierSuccess: false,
        updateCourierErrorMessage: action.error,
      };

    case DELETING_COURIER:
      return {
        ...state,
        deletingCourier: true,
        deleteCourierSuccess: false,
        deleteCourierErrorMessage: null,
      };

    case DELETE_COURIER_SUCCESS:
      const nonDeletedCouriers =
        state.couriers && state.couriers?.filter((d: Courier) => d.courierId !== action.courierId);

      return {
        ...state,
        deletingCourier: false,
        deleteCourierSuccess: true,
        couriers: nonDeletedCouriers,
        deleteCourierErrorMessage: null,
      };

    case DELETE_COURIER_FAILED:
      return {
        ...state,
        deletingCourier: false,
        deleteCourierSuccess: false,
        deleteCourierErrorMessage: action.error,
      };

    case ADDING_VEHICLE:
      return {
        ...state,
        addingVehicle: true,
        addVehicleSuccess: false,
        addVehicleErrorMessage: null,
      };

    case ADD_VEHICLE_SUCCESS:
      const newVehicles = state?.courier?.vehicle ? [...state.courier.vehicle] : [];
      newVehicles.push(action.vehicle);
      const newCourierss = state.couriers;
      if (newCourierss) {
        const updatedCourierIndex = newCourierss?.findIndex((p) => p.courierId === action.vehicle.courierId);
        newCourierss[updatedCourierIndex].vehicle = newVehicles;
      }
      return {
        ...state,
        addingVehicle: false,
        addVehicleSuccess: true,
        couriers: newCourierss,
      };

    case ADD_VEHICLE_FAILED:
      return {
        ...state,
        addingVehicle: false,
        addVehicleSuccess: false,
        addVehicleErrorMessage: action.error,
      };

    case UPDATING_VEHICLE:
      return {
        ...state,
        updatingVehicle: true,
        updateVehicleSuccess: false,
        updateVehicleErrorMessage: null,
      };

    case UPDATE_VEHICLE_SUCCESS:
      // TODO make this work
      /*       if (state?.courier?.vehicle) {
        let updatedCourier = state.courier;
        let updatedvehicles: Vehicle[] = [];
        state.courier.vehicle.map(v => {
          if (v.id === action.vehicle.id) {
            updatedvehicles.push(action.vehicle)
          } else {
            updatedvehicles.push(v)
          }
        })
        updatedCourier.vehicle = updatedvehicles;
        let updatedCouriers = state.couriers || [];
        const updatedCourierIndex = updatedCouriers?.findIndex(
          c => c.courierId === action.vehicle.courierId)
        updatedCouriers[updatedCourierIndex] = updatedCourier;
        return {
          ...state,
          couriers: updatedCouriers,
          courier: updatedCourier,
          updatingVehicle: false,
          updateVehicleSuccess: true,
          updateVehicleErrorMessage: null
        }
      } */
      return {
        ...state,
        updatingVehicle: false,
        updateVehicleSuccess: true,
        updateVehicleErrorMessage: null,
      };

    case UPDATE_VEHICLE_FAILED:
      return {
        ...state,
        updatingVehicle: false,
        updateVehicleSuccess: false,
        updateVehicleErrorMessage: action.error,
      };

    case DELETING_VEHICLE:
      return {
        ...state,
        deletingVehicle: true,
        deleteVehicleSuccess: false,
        deleteVehicleErrorMessage: null,
      };

    case DELETE_VEHICLE_SUCCESS:
      // TODO make this work
      /*       if (state?.courier?.vehicle) {
        const newCourier = state.courier;
        let newVehicles = state.courier.vehicle.map ((v) => {
          if (v.id === Number(action.vehicleId)) {
            return v
          }
        })
        newCourier.vehicle = newVehicles;
        let newCouriers = state.couriers || [];
        return {
          ...state,
          couriers: newCouriers.map((c) => {
            if (c.courierId === newCourier.courierId ) {
              return {
                ...c,
                vehicle: newVehicles
              }
            } else {
              return c
            };
          }),
          courier: {
            ...state.courier,
            vehicle: newVehicles
          },
          updatingVehicle: false,
          updateVehicleSuccess: true,
          updateVehicleErrorMessage: null
        };
      } */
      return {
        ...state,
        deletingVehicle: false,
        deleteVehicleSuccess: true,
        deleteVehicleErrorMessage: null,
      };

    case DELETE_VEHICLE_FAILED:
      return {
        ...state,
        deletingVehicle: false,
        deleteVehicleSuccess: false,
        deleteVehicleErrorMessage: action.error,
      };

    case CLEAR_COURIERS_STATE:
      return {
        ...state,
        couriersErrorMessage: null,
        createCourierSuccess: false,
        createCourierErrorMessage: null,
        updateCourierSuccess: false,
        updateCourierErrorMessage: null,
        deleteCourierErrorMessage: null,
        addVehicleErrorMessage: null,
        updateVehicleErrorMessage: null,
        deleteVehicleErrorMessage: null,
      };

    default:
      return state;
  }
}

import { v4 as uuidv4 } from 'uuid';
import { extendDeliveryOrder } from '../../utils/deliveryOrders.helpers';
import {
  DeliveryOrdersActionTypes,
  FETCHING_DELIVERY_ORDERS,
  FETCH_DELIVERY_ORDERS_SUCCESS,
  FETCH_DELIVERY_ORDERS_FAILED,
  CREATING_DELIVERY_ORDER,
  CREATE_DELIVERY_ORDER_SUCCESS,
  CREATE_DELIVERY_ORDER_FAILED,
  PARSING_CSV,
  PARSE_CSV_SUCCESS,
  PARSE_CSV_FAILED,
  EDIT_CSV_LINE,
  DELETE_CSV_LINE,
  CLEAR_DELIVERY_ORDERS_STATE,
} from '../config/ActionTypes';
import {
  CSVLine,
  DeliveryOrder,
  DeliveryOrdersState,
  ExtendedDeliveryOrder,
} from '../config/types/deliveryOrders.types';

export const initialState: DeliveryOrdersState = {
  loadingDeliveryOrders: false,
  deliveryOrdersFetched: false,
  deliveryOrders: null,
  deliveryOrder: null,
  deliveryOrdersErrorMessage: null,
  creatingDeliveryOrder: false,
  createDeliveryOrderSuccess: false,
  createDeliveryOrderErrorMessage: null,
  parsingCSV: false,
  parsedCSVLines: null,
  parseCSVSuccess: false,
  parseCSVErrorMessage: null,
};

export function deliveryOrdersReducer(state = initialState, action: DeliveryOrdersActionTypes): DeliveryOrdersState {
  switch (action.type) {
    case FETCHING_DELIVERY_ORDERS:
      return {
        ...state,
        loadingDeliveryOrders: true,
        deliveryOrders: null,
        deliveryOrdersErrorMessage: null,
      };

    case FETCH_DELIVERY_ORDERS_SUCCESS:
      const extendedDeliveryOrders: ExtendedDeliveryOrder[] = action.deliveryOrders.map(
        (deliveryOrder: DeliveryOrder) => extendDeliveryOrder(deliveryOrder),
      );

      return {
        ...state,
        loadingDeliveryOrders: false,
        deliveryOrdersFetched: true,
        deliveryOrders: extendedDeliveryOrders,
        deliveryOrdersErrorMessage: null,
      };

    case FETCH_DELIVERY_ORDERS_FAILED:
      return {
        ...state,
        loadingDeliveryOrders: false,
        deliveryOrdersFetched: true,
        deliveryOrders: null,
        deliveryOrdersErrorMessage: action.error,
      };

    case CREATING_DELIVERY_ORDER:
      return {
        ...state,
        creatingDeliveryOrder: true,
        createDeliveryOrderSuccess: false,
        createDeliveryOrderErrorMessage: null,
      };

    case CREATE_DELIVERY_ORDER_SUCCESS:
      const newDeliveryOrders = state.deliveryOrders ? [...state.deliveryOrders] : [];
      newDeliveryOrders.push(extendDeliveryOrder(action.deliveryOrder));

      return {
        ...state,
        deliveryOrders: newDeliveryOrders,
        creatingDeliveryOrder: false,
        createDeliveryOrderSuccess: true,
        createDeliveryOrderErrorMessage: null,
      };

    case CREATE_DELIVERY_ORDER_FAILED:
      return {
        ...state,
        creatingDeliveryOrder: false,
        createDeliveryOrderSuccess: false,
        createDeliveryOrderErrorMessage: action.error,
      };

    case PARSING_CSV:
      return {
        ...state,
        parsingCSV: true,
        parsedCSVLines: null,
        parseCSVSuccess: false,
        parseCSVErrorMessage: null,
      };

    case PARSE_CSV_SUCCESS:
      return {
        ...state,
        parsingCSV: false,
        parsedCSVLines: action.parsedCSVLines.map((csvLine: CSVLine) => {
          return {
            ...csvLine,
            packageId: uuidv4(),
          };
        }),
        parseCSVSuccess: true,
        parseCSVErrorMessage: null,
      };

    case PARSE_CSV_FAILED:
      return {
        ...state,
        parsingCSV: false,
        parsedCSVLines: null,
        parseCSVSuccess: false,
        parseCSVErrorMessage: action.error,
      };

    case EDIT_CSV_LINE:
      const updatedCSVLines =
        state.parsedCSVLines &&
        state.parsedCSVLines.map((item: CSVLine) => {
          if (item.packageId !== action.csvLine.packageId) {
            return item;
          }
          return {
            ...action.csvLine,
          };
        });

      return {
        ...state,
        parsedCSVLines: updatedCSVLines,
      };

    case DELETE_CSV_LINE:
      const nonDeletedCSVLines =
        state.parsedCSVLines &&
        state.parsedCSVLines.filter((csvLine: CSVLine) => csvLine.packageId !== action.packageId);

      return {
        ...state,
        parsedCSVLines: nonDeletedCSVLines,
      };

    case CLEAR_DELIVERY_ORDERS_STATE:
      return {
        ...state,
        deliveryOrdersErrorMessage: null,
        createDeliveryOrderSuccess: false,
        createDeliveryOrderErrorMessage: null,
        parseCSVErrorMessage: null,
      };

    default:
      return state;
  }
}

import { ContactIcon, DeliveryOrderIcon, HomeIcon, ProfileIcon, WarehousesIcon } from '../assets/images';
/* import Couriers from '../screens/Couriers/Couriers'; */
import CouriersForm from '../screens/Couriers/CouriersForm';
import Customers from '../screens/Customers/Customers';
import CustomersForm from '../screens/Customers/CustomersForm';
import Home from '../screens/Home/Home';
import DeliveryOrders from '../screens/DeliveryOrders/DeliveryOrders';
import DeliveryOrderForm from '../screens/DeliveryOrders/DeliveryOrderForm';
import Profile from '../screens/Profile/Profile';
import VehiclesForm from '../screens/Couriers/VehiclesForm';
import Warehouses from '../screens/Warehouses/Warehouses';
import WarehouseForm from '../screens/Warehouses/WarehouseForm';

const routes = [
  {
    path: '/home',
    sidebarName: 'Home',
    navbarName: 'Home',
    icon: HomeIcon,
    component: Home,
  },
  {
    path: '/delivery-orders',
    sidebarName: 'Delivery Orders',
    navbarName: 'Delivery Orders',
    icon: DeliveryOrderIcon,
    component: DeliveryOrders,
  },
  {
    path: '/delivery-orders/create',
    component: DeliveryOrderForm,
  },
  {
    path: '/delivery-orders/:deliveryOrderId/detail',
    component: DeliveryOrderForm,
  },
  { path: '/customers', sidebarName: 'Customers', navbarName: 'Customers', icon: ContactIcon, component: Customers },
  {
    path: '/customers/create',
    component: CustomersForm,
  },
  {
    path: '/customers/:customerId/update',
    component: CustomersForm,
  },
  {
    path: '/couriers/create',
    component: CouriersForm,
  },
  {
    path: '/couriers/:courierId/update',
    component: CouriersForm,
  },
  {
    path: '/couriers/:courierId/vehicle/create',
    component: VehiclesForm,
  },
  {
    path: '/couriers/:courierId/vehicle/:vehicleId/update',
    component: VehiclesForm,
  },
  {
    path: '/warehouses',
    sidebarName: 'Warehouses',
    navbarName: 'Warehouses',
    icon: WarehousesIcon,
    component: Warehouses,
  },
  {
    path: '/warehouses/create',
    component: WarehouseForm,
  },
  {
    path: '/warehouses/:warehouseId/update',
    component: WarehouseForm,
  },
  {
    path: '/manage-profile',
    sidebarName: 'Manage Profile',
    navbarName: 'Manage Profile',
    icon: ProfileIcon,
    component: Profile,
  },
];

export default routes;

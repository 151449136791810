import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { RootState } from '../../store/config/types';
import { fetchDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import { ExtendedDeliveryOrder, ExtendedPackageGroup } from '../../store/config/types/deliveryOrders.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { dataTitle, TitleSection } from '../../utils/homeData';
import Button from '../../components/CustomButtons/Button';
import CardHome from '../../components/Card/CardHome';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    deliveryOrders: state.deliveryOrders,
    warehouses: state.warehouses,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Home({ auth, deliveryOrders, warehouses, loggedIn, fetchDeliveryOrders, fetchWarehouses }: PropsFromRedux) {
  const history: History = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (
      !deliveryOrders.loadingDeliveryOrders &&
      !deliveryOrders.deliveryOrders &&
      !deliveryOrders.deliveryOrdersErrorMessage
    ) {
      fetchDeliveryOrders();
    }
  }, [
    fetchDeliveryOrders,
    deliveryOrders.deliveryOrders,
    deliveryOrders.deliveryOrdersErrorMessage,
    deliveryOrders.loadingDeliveryOrders,
    loggedIn,
  ]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage &&
      auth.account
    ) {
      fetchWarehouses(auth.account?.shipperId || 0);
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
    auth.account,
  ]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateDeliveryOrder = () => history.push('/delivery-orders/create');

  const handleUpdateDeliveryOrder = (deliveryOrderId: string) =>
    history.push(`/delivery-orders/${deliveryOrderId}/detail`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
    </GridContainer>
  );

  let deliveryOrdersList = null;

  if (deliveryOrders.deliveryOrders) {
    let deliveryOrdersToShow: ExtendedDeliveryOrder[] =
      deliveryOrders.deliveryOrders?.filter((deliveryOrder: ExtendedDeliveryOrder) =>
        moment(deliveryOrder.deliveryExpectedAt).isSame(new Date(), 'day'),
      ) || [];

    if (searchText !== '') {
      deliveryOrdersToShow = deliveryOrdersToShow.filter((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find((w: Warehouse) => w.warehouseId === deliveryOrder.warehouseId);
        if (warehouse?.name?.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }

        if (
          moment
            .utc(deliveryOrder.deliveryExpectedAt)
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return true;
        }

        return ['packagesAmount', 'numberOfRecipients'].some((key) => {
          return (deliveryOrder as any)[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = deliveryOrdersToShow
      ?.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((deliveryOrder: ExtendedDeliveryOrder) => {
        const warehouse = warehouses.warehouses?.find((w: Warehouse) => w.warehouseId === deliveryOrder.warehouseId);

        return [
          deliveryOrder.deliveryOrderId,
          warehouse?.name,
          moment.utc(deliveryOrder.deliveryExpectedAt).format('MM/DD/YYYY'),
          deliveryOrder.packagesAmount,
          deliveryOrder.numberOfRecipients,
        ];
      });

    deliveryOrdersList = (
      <TableList
        title="Today deliveries"
        head={['Id', 'Warehouse', 'Expected date', 'Number of packages', 'Number of recipients', '']}
        onShowDetail={handleUpdateDeliveryOrder}
        detail
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={deliveryOrdersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (deliveryOrders.loadingDeliveryOrders) {
    deliveryOrdersList = <Spinner />;
  } else {
    deliveryOrdersList = <div>No data to show</div>;
  }

  if (!deliveryOrders.deliveryOrdersFetched) {
    return <Spinner />;
  }

  const titleContent = (
    <div className="card">
      {dataTitle.map((data: TitleSection) => {
        const amount: number =
          data.status === 'ALL'
            ? deliveryOrders.deliveryOrders?.reduce((accumulator: number, currentValue: ExtendedDeliveryOrder) => {
                return accumulator + currentValue.packageGroups.length;
              }, 0) || 0
            : deliveryOrders.deliveryOrders?.reduce((accumulator: number, deliveryOrder: ExtendedDeliveryOrder) => {
                return (
                  accumulator +
                  deliveryOrder.packageGroups.filter(
                    (packageGroup: ExtendedPackageGroup) => packageGroup.packageGroupStatus === data.title,
                  ).length
                );
              }, 0) || 0;
        return <CardHome key={data.title} image={data.image} amount={amount} title={data.title} color={data.color} />;
      })}
    </div>
  );

  return (
    <Fragment>
      {titleContent}
      {deliveryOrdersList}
      <Button className="addButton" variant="contained" color="primary" onClick={handleCreateDeliveryOrder}>
        Create Delivery Order
      </Button>
    </Fragment>
  );
}

export default connector(Home);

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/toast.actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addVehicle, updateVehicle } from '../../store/actions/couriers.actions';
import { VehicleEnum } from '../../store/config/enums/vehicle.enum';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CustomInput from '../../components/CustomInput/CustomInput';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

const VehicleForm = ({ onAddVehicle, onUpdateVehicle, setToast, couriers }) => {
  const passedInState = useLocation().state;
  const courier = couriers.courier;
  const editMode = passedInState?.editMode || false;
  const { vehicleId } = useParams();
  const history = useHistory();
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    vehicleType: '',
    licensePlate: '',
    model: '',
    color: '',
    capacity: '',
    courierId: courier?.courierId,
  });

  useEffect(() => {
    if (!couriers) {
      history.push('/couriers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMode) {
      const vehicleIndex = courier.vehicle?.findIndex((v) => v.id === Number(vehicleId));
      const vehicle = courier.vehicle[vehicleIndex];
      setFormData({
        vehicleType: vehicle.vehicleType,
        licensePlate: vehicle.licensePlate,
        model: vehicle.model,
        color: vehicle.color,
        capacity: vehicle.capacity,
        courierId: courier?.courierId,
        id: vehicleId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submit && !couriers.addingVehicle) {
      if (couriers.addVehicleSuccess) {
        setSubmit(false);
        setToast('Vehicle succesfully created', 'success');
        history.push(`/couriers/${courier.courierId}/update`);
      } else {
        setSubmit(false);
        setToast('An error has ocurred while creating vehicle', 'danger');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriers.addVehicleSuccess, couriers.addVehicleErrorMessage]);

  useEffect(() => {
    if (submit && !couriers.updatingVehicle) {
      setSubmit(false);
      if (couriers.updateVehicleSuccess) {
        setToast('Vehicle successfully updated', 'success');
        history.push('/couriers'); //todo fix redux and redirect to update
      } else {
        setToast('An error has ocurred while updating vehicle', 'danger');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriers.updateVehicleSuccess, couriers.updateVehicleErrorMessage]);

  const inputChangedHandler = (event, inputIdentifier) => {
    setFormData({ ...formData, [inputIdentifier]: event.target.value });
  };

  const handleSubmit = async () => {
    setSubmit(true);
    if (editMode) {
      await onUpdateVehicle(formData);
    } else {
      await onAddVehicle(formData);
    }
  };

  const canSubmitVehicle = (form) => {
    return (
      form.vehicleType === '' ||
      (form.vehicleType !== 'BIKE' &&
        form.vehicleType !== 'WALKER' &&
        (form.licensePlate === '' || form.model === '' || form.color === '' || form.capacity === ''))
    );
  };

  return (
    <div>
      <Card profile className="vehicle-form">
        <CardBody profile>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <FormControl variant="standard" fullWidth className="vehicle-type-select">
                <InputLabel id="vehicle-type-select-label">Vehicle type</InputLabel>
                <Select
                  labelId="vehicle-type-select-label"
                  id="vehicle-type-select"
                  value={formData.vehicleType}
                  label="Vehicle Type"
                  onChange={(event) => inputChangedHandler(event, 'vehicleType')}
                >
                  <MenuItem value={VehicleEnum.CAR}>{VehicleEnum[VehicleEnum.CAR]}</MenuItem>
                  <MenuItem value={VehicleEnum.TRUCK}>{VehicleEnum[VehicleEnum.TRUCK]}</MenuItem>
                  <MenuItem value={VehicleEnum.MOTORCYCLE}>{VehicleEnum[VehicleEnum.MOTORCYCLE]}</MenuItem>
                  <MenuItem value={VehicleEnum.BIKE}>{VehicleEnum[VehicleEnum.BIKE]}</MenuItem>
                  <MenuItem value={VehicleEnum.WALKER}>{VehicleEnum[VehicleEnum.WALKER]}</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="License plate"
                id="licensePlate"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.licensePlate,
                  onChange: (event) => inputChangedHandler(event, 'licensePlate'),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Vehicle model"
                id="model"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.model,
                  onChange: (event) => inputChangedHandler(event, 'model'),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Vehicle color"
                id="color"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.color,
                  onChange: (event) => inputChangedHandler(event, 'color'),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Vehicle capacity"
                id="capacity"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.capacity,
                  onChange: (event) => inputChangedHandler(event, 'capacity'),
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            className="submit-button"
            color="primary"
            disabled={canSubmitVehicle(formData)}
            onClick={handleSubmit}
          >
            {editMode ? 'UPDATE' : 'CREATE'} VEHICLE
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  couriers: state.couriers,
  couriersList: state.couriers.couriers,
});

const mapDispatchToProps = (dispatch) => ({
  onAddVehicle: (vehicle) => dispatch(addVehicle(vehicle)),
  onUpdateVehicle: (vehicle) => dispatch(updateVehicle(vehicle)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleForm);

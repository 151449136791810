import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { LoggedAccount, RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { updateAccount, getAccount } from '../../store/actions/auth.actions';
import { setToast } from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';

interface ProfileValues {
  firstName: string;
  lastName: string;
  phoneNumber: number;
  email: string;
  homeAddress: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getAccount: (shipperId: number) => dispatch(getAccount(shipperId)),
  updateAccount: (shipperId: number, shipperData: LoggedAccount) => dispatch(updateAccount(shipperId, shipperData)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Profile({ auth, updateAccount, setToast, getAccount }: PropsFromRedux) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [address, setAddress] = useState<string>(auth.account?.homeAddress || '');

  useEffect(() => {
    if (submitting && !auth.updatingAccount) {
      if (auth.updateAccountSuccess) {
        setSubmitting(false);
        setToast('Profile successfully updated', 'success');
      }
      if (auth.updateAccountErrorMessage !== null) {
        setSubmitting(false);
        setToast('An error has ocurred while updating profile', 'danger');
      }
    }
  }, [
    submitting,
    setSubmitting,
    auth.updatingAccount,
    auth.updateAccountSuccess,
    auth.updateAccountErrorMessage,
    setToast,
  ]);

  const submit = async (values: ProfileValues) => {
    setSubmitting(true);
    const updatedAccount: LoggedAccount = {
      ...auth.account,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: Number(values.phoneNumber),
      email: values.email,
      homeAddress: address,
    };

    await updateAccount(auth.account!.shipperId!, updatedAccount);
    await getAccount(auth.account!.shipperId!);
  };

  if (auth.account === null) {
    return <Spinner />;
  }

  return (
    <div className="profile">
      <Formik
        initialValues={{
          firstName: auth.account?.firstName || '',
          lastName: auth.account?.lastName || '',
          phoneNumber: auth.account?.phoneNumber || 0,
          email: auth.account?.email || '',
          homeAddress: auth.account.homeAddress! || '',
          companyName: auth.account?.companyName || '',
        }}
        validationSchema={schemas.ProfileSchema}
        onSubmit={submit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Card profile>
              <CardHeader color="primary" className="card-header">
                {`${auth.account?.firstName} ${auth.account?.lastName}`}
              </CardHeader>
              <CardBody>
                <h3 className="section-title">Personal info</h3>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="First name" name="firstName" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Last name" name="lastName" />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Email" name="email" disabled={true} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Company name" name="companyName" disabled={true} />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput placeholder="Phone number" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={false}
                    inputSize={6}
                  />
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button className="submit-button" type="submit" color="primary">
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default connector(Profile);

import { useEffect, useCallback, Dispatch } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/config/types';
import { AppActions } from '../store/config/ActionTypes';
import * as actions from '../store/actions/auth.actions';
import indexRoutes from '../utils/routes';
import Layout from '../components/Layout/Layout';
import Spinner from '../components/Spinner/Spinner';
import Login from './Auth/Login';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  logout: () => dispatch(actions.logout()),
  getAccount: (shipperId: number) => dispatch(actions.getAccount(shipperId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Main({ auth, getAccount }: PropsFromRedux) {
  const dispatch: Dispatch<any> = useDispatch();
  const history: History = useHistory();
  const currentURL: string = window.location.href;

  useEffect(() => {
    if (!auth.loggedIn) {
      const token: string | null = localStorage.getItem('token');
      if (!token) {
        if (!currentURL.includes('login')) {
          history.push('/login');
          location.reload();
        }
      } else {
        const shipperId: string | null = localStorage.getItem('shipperId');
        getAccount(Number(shipperId));
      }
    } else if (currentURL.includes('login')) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, auth.loggedIn]);

  const handleOnLogout = useCallback(() => {
    history.push('/login');
    dispatch(actions.logout());
  }, [dispatch, history]);

  if (!auth.loggedIn && !currentURL.includes('login')) {
    return (
      <div className="SpinnerContainer">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Layout onLogout={handleOnLogout}>
          {indexRoutes.map((route, key) => {
            return <Route exact path={route.path} component={route.component} key={key} />;
          })}
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          {!auth.loggedIn && <Redirect from="/" to="/login" />}
        </Layout>
      </Switch>
    </>
  );
}

export default connector(Main);

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { RootState } from '../../store/config/types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { setToast } from '../../store/actions/toast.actions';
import {
  createWarehouse,
  fetchWarehouses,
  updateWarehouse,
  clearWarehousesState,
} from '../../store/actions/warehouses.actions';
import schemas from '../../utils/schemas';
import { Coordinates } from '../../store/config/types/location.types';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';

interface WarehouseParams {
  warehouseId?: string;
}

interface WarehouseValues {
  name: string;
  descriptions: string;
  streetAddress: string;
  phoneNumber: string;
  pointOfContact: string;
  email: string;
}

interface WarehouseFormProps {
  fromModal?: boolean;
  closeModal?(): void;
  submitModal?(): void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  warehouses: state.warehouses,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateWarehouse: (warehouse: Warehouse) => dispatch(createWarehouse(warehouse)),
  onUpdateWarehouse: (warehouseId: number, warehouse: Warehouse) => dispatch(updateWarehouse(warehouseId, warehouse)),
  clearWarehousesState: () => dispatch(clearWarehousesState()),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & WarehouseFormProps;

function WarehouseForm({
  auth,
  warehouses,
  onCreateWarehouse,
  onUpdateWarehouse,
  fetchWarehouses,
  clearWarehousesState,
  setToast,
  fromModal,
  closeModal,
  submitModal,
}: PropsType) {
  const history: History = useHistory();
  const { warehouseId } = useParams<WarehouseParams>();
  const editMode: boolean = Boolean(warehouseId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(null);
  const [address, setAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<Coordinates>({ lat: null, lng: null });
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  useEffect(() => {
    if (editMode && selectedWarehouse === null && warehouses.warehouses && warehouseId) {
      const editedWarehouse = warehouses.warehouses.filter((w: Warehouse) => w.warehouseId === Number(warehouseId))[0];
      setSelectedWarehouse(editedWarehouse);
      setAddress(editedWarehouse.location.streetAddress);
      setCoordinates({ lat: editedWarehouse.location.latitude, lng: editedWarehouse.location.longitude });
      setCity(editedWarehouse.location.city!);
      setState(editedWarehouse.location.state!);
      setZipCode(editedWarehouse.location.zipCode!);
    }
  }, [editMode, selectedWarehouse, warehouses.warehouses, warehouseId, setSelectedWarehouse]);

  useEffect(() => {
    if (!warehouses.warehouses && auth.account) {
      fetchWarehouses(auth.account?.shipperId || 0);
    }
  }, [warehouses.warehouses, auth.account, fetchWarehouses]);

  useEffect(() => {
    if (submitting) {
      if (warehouses.createWarehouseSuccess || warehouses.updateWarehouseSuccess) {
        setSubmitting(false);
        setToast(`Warehouse successfully ${warehouses.createWarehouseSuccess ? 'created' : 'updated'}`, 'success');
        clearWarehousesState();

        if (fromModal) {
          submitModal && submitModal();
        } else {
          history.push('/warehouses');
        }
      } else if (warehouses.createWarehouseErrorMessage || warehouses.updateWarehouseErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${warehouses.createWarehouseErrorMessage ? 'creating' : 'updating'} warehouse`,
          'danger',
        );
        clearWarehousesState();
      }
    }
  }, [
    submitting,
    warehouses.createWarehouseSuccess,
    warehouses.updateWarehouseSuccess,
    warehouses.createWarehouseErrorMessage,
    warehouses.updateWarehouseErrorMessage,
    history,
    fromModal,
    submitModal,
    setSubmitting,
    setToast,
    clearWarehousesState,
  ]);

  const handleGoBack = () => (fromModal ? closeModal && closeModal() : history.push('/warehouses'));

  const submitForm = (values: WarehouseValues) => {
    if (auth.account) {
      const warehouseRequest: Warehouse = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        pointOfContact: values.pointOfContact,
        descriptions: values.descriptions,
        email: values.email,
        location: {
          latitude: coordinates.lat || 0,
          longitude: coordinates.lng || 0,
          streetAddress: address,
          city: city,
          state: state,
          zipCode: zipCode,
        },
        availability: true,
      };
      setSubmitting(true);

      if (editMode) {
        onUpdateWarehouse(Number(selectedWarehouse?.warehouseId), warehouseRequest);
      } else {
        warehouseRequest.shipperId = auth.account.shipperId!;
        onCreateWarehouse(warehouseRequest);
      }
    }
  };

  if (editMode && !selectedWarehouse) {
    return <Spinner />;
  }

  return (
    <div className={`warehouse-form ${fromModal && 'modal-form'}`}>
      <Card profile>
        <CardHeader color="primary" className="warehouse-header">
          <h4>{editMode && selectedWarehouse ? `${selectedWarehouse.name}` : 'New warehouse'}</h4>
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              name: selectedWarehouse ? selectedWarehouse.name! : '',
              descriptions: selectedWarehouse ? selectedWarehouse.descriptions! : '',
              streetAddress: selectedWarehouse ? selectedWarehouse.location.streetAddress! : '',
              phoneNumber: selectedWarehouse ? selectedWarehouse?.phoneNumber! : '',
              pointOfContact: selectedWarehouse ? selectedWarehouse?.pointOfContact! : '',
              email: selectedWarehouse ? selectedWarehouse?.email! : '',
            }}
            validationSchema={schemas.WarehouseSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Name" name="name" type="text" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setState={setState}
                    setZipCode={setZipCode}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputSize={6}
                  />
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Contact name" name="pointOfContact" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput placeholder="Contact phone" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Contact email" name="email" type="email" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Notes" name="descriptions" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {`${editMode ? 'Update' : 'Create'} warehouse`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
}

export default connector(WarehouseForm);

import * as Yup from 'yup';

const requiredString: string = 'Required field';
const wrongEmailString: string = 'Wrong email format';
const repeatedValueString: string = 'Value is repeated';

const onlyNumbersRegex: RegExp = /^[0-9]+$/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(wrongEmailString).required(requiredString),
  password: Yup.string().required(requiredString),
});

const WarehouseSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  descriptions: Yup.string(),
  streetAddress: Yup.string().required(requiredString),
  // shipper: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  pointOfContact: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
});

const DeliveryOrderSchema = Yup.object().shape({
  date: Yup.date().required(requiredString),
  warehouseId: Yup.number().required(requiredString),
});

const PackageSchema = Yup.object().shape({
  packagesAmount: Yup.number().positive().required(requiredString),
});

const CustomerSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
  contactPhoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  streetAddress: Yup.string().required(requiredString),
});

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  homeAddress: Yup.string().required(requiredString),
});

// TODO - Try to make a generic test function
const CSVSchema = Yup.object().shape({
  customerFirstName: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        const array = [
          this.parent.customerLastName,
          this.parent.customerAddressLineOne,
          this.parent.customerAddressLineTwo,
          this.parent.customerAddressLineThree,
          this.parent.customerAddressUnit,
          this.parent.numberOfPackages,
          this.parent.largestPackageSize,
        ];
        return array.indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerLastName: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        const array = [
          this.parent.customerFirstName,
          this.parent.customerAddressLineOne,
          this.parent.customerAddressLineTwo,
          this.parent.customerAddressLineThree,
          this.parent.customerAddressUnit,
          this.parent.numberOfPackages,
          this.parent.largestPackageSize,
        ];
        return array.indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerAddressLineOne: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        const array = [
          this.parent.customerFirstName,
          this.parent.customerLastName,
          this.parent.customerAddressLineTwo,
          this.parent.customerAddressLineThree,
          this.parent.customerAddressUnit,
          this.parent.numberOfPackages,
          this.parent.largestPackageSize,
        ];
        return array.indexOf(value) === -1;
      },
    })
    .required(requiredString),
  numberOfPackages: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        const array = [
          this.parent.customerFirstName,
          this.parent.customerLastName,
          this.parent.customerAddressLineOne,
          this.parent.customerAddressLineTwo,
          this.parent.customerAddressLineThree,
          this.parent.customerAddressUnit,
          this.parent.largestPackageSize,
        ];
        return array.indexOf(value) === -1;
      },
    })
    .required(requiredString),
  largestPackageSize: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        const array = [
          this.parent.customerFirstName,
          this.parent.customerLastName,
          this.parent.customerAddressLineOne,
          this.parent.customerAddressLineTwo,
          this.parent.customerAddressLineThree,
          this.parent.customerAddressUnit,
          this.parent.numberOfPackages,
        ];
        return array.indexOf(value) === -1;
      },
    })
    .required(requiredString),
});

const schemas = {
  LoginSchema,
  DeliveryOrderSchema,
  PackageSchema,
  WarehouseSchema,
  CustomerSchema,
  ProfileSchema,
  CSVSchema,
};

export default schemas;

import { withStyles, Button } from '@material-ui/core';
import Logout from '@material-ui/icons/ExitToApp';
import headerLinkStyle from '../../../assets/jss/headerLinkStyle';

function HeaderLinks({ classes, onLogoutClick }) {
  return (
    <div>
      <Button className={classes.logoutBtn} onClick={onLogoutClick}>
        <Logout className={classes.icons} />
      </Button>
    </div>
  );
}

export default withStyles(headerLinkStyle)(HeaderLinks);

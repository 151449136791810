import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/config/types';
import { Customer } from '../../store/config/types/customers.types';
import { fetchCustomers } from '../../store/actions/customers.actions';
import { maskPhone } from '../../utils/string.helpers';
import AddButton from '../../components/CustomButtons/AddButton';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SpecialInput from '../../components/SpecialInput/Input';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    customers: state.customers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCustomers: () => dispatch(fetchCustomers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Customers({ customers, fetchCustomers }: PropsFromRedux) {
  const history: History = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!customers.loadingCustomers && !customers.customers && !customers.customersErrorMessage) {
      fetchCustomers();
    }
  }, [fetchCustomers, customers.customers, customers.customersErrorMessage, customers.loadingCustomers]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateCustomer = () => history.push('/customers/create');

  const handleUpdateCustomer = (customerId: string) => history.push(`/customers/${customerId}/update`);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={searchTextHandler}
        />
      </GridItem>
    </GridContainer>
  );

  let customersContent = null;

  if (customers.customers) {
    let customersToShow: Customer[] = customers.customers || [];

    if (searchText !== '') {
      customersToShow = customersToShow.filter((customer: Customer) => {
        return [
          'firstName',
          'lastName',
          'email',
          'contactPhoneNumber',
          'streetAddress',
          'unit',
          'city',
          'state',
          'zipCode',
          'specialInstructions',
        ].some((key) => {
          const searchableText =
            (customer && (customer as any)[key]) || (customer.location && (customer as any)?.location[key]);
          return searchableText?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = customersToShow
      .sort((a: any, b: any) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
      ?.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((customer: Customer) => {
        return [
          customer.customerId,
          `${customer.firstName || ''} ${customer.lastName || ''}`,
          maskPhone(customer.contactPhoneNumber),
          customer.location?.streetAddress,
          customer.location?.apartmentNumber,
          customer.specialInstructions,
        ];
      });

    customersContent = (
      <TableList
        title="Customers"
        head={['Id', 'Contact Person', 'Phone Number', 'Address', 'Unit', 'Instructions', '']}
        onEdit={handleUpdateCustomer}
        edit
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={customersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (customers.loadingCustomers) {
    customersContent = <Spinner />;
  } else {
    customersContent = <div>No data to show</div>;
  }

  if (customers.loadingCustomers) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {customersContent}
      <AddButton onClick={handleCreateCustomer} />
    </Fragment>
  );
}

export default connector(Customers);

import { useState, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { drawerWidth, transition, container } from '../../assets/jss/material-dashboard-react';
import { RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { removeToast } from '../../store/actions/toast.actions';
import routes from '../../utils/routes';
import Header from '../../components/Header/Header';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Snackbar from '../../components/Snackbar/Snackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { position: 'relative', top: '0', height: '100vh' },
    mainPanel: {
      [theme.breakpoints.up('md')]: { width: `calc(100% - ${drawerWidth}px)` },
      overflow: 'visible',
      position: 'relative',
      float: 'right',
      ...transition,
      maxHeight: '100%',
      width: '100%',
      overflowScrolling: 'touch',
    },
    content: {
      padding: '30px 15px',
      minHeight: 'calc(100vh - 123px)',
      [theme.breakpoints.down('sm')]: { padding: '0' },
    },
    container: { ...container, [theme.breakpoints.down('sm')]: { padding: '0' } },
    map: { marginTop: '70px' },
  }),
);

const SNACKBAR_ICON: { [key: string]: any } = {
  success: CheckCircle,
  danger: ErrorIcon,
};

interface LayoutProps {
  children: ReactNode;
  onLogout: () => void;
}

const mapStateToProps = (state: RootState) => ({
  showToast: state.toast.show,
  toastMessage: state.toast.message,
  toastMessageType: state.toast.messageType,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  onCloseToast: () => dispatch(removeToast()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LayoutProps;

function Layout({ showToast, toastMessage, toastMessageType, onCloseToast, onLogout, children }: PropsType) {
  const classes = useStyles();
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);

  const sideDrawerClosedHandler = () => setShowSideDrawer(false);

  const sideDrawerToggleHandler = () => setShowSideDrawer((prevState) => !prevState);

  return (
    <div className={classes.wrapper}>
      <SideDrawer
        open={showSideDrawer}
        closed={sideDrawerClosedHandler}
        onItemSelected={sideDrawerClosedHandler}
        handleDrawerOpen={sideDrawerToggleHandler}
      />

      <div className={classes.mainPanel}>
        <Header drawerToggleClicked={sideDrawerToggleHandler} routes={routes} handleLogout={onLogout} />
        <div className={classes.content}>
          <div className={classes.container}>{children}</div>
        </div>
      </div>
      <Snackbar
        place="bl"
        color={toastMessageType}
        icon={SNACKBAR_ICON[toastMessageType]}
        message={toastMessage}
        open={showToast}
        closeNotification={onCloseToast}
        close
      />
    </div>
  );
}

export default connector(Layout);

import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { RootState } from '../../store/config/types';
import { ExtendedDeliveryOrder, PackageModel } from '../../store/config/types/deliveryOrders.types';
import { deleteCSVLine } from '../../store/actions/deliveryOrders.actions';
import { createPackagesList, transformCSVLines } from '../../utils/deliveryOrders.helpers';
import GridItem from '../../components/Grid/GridItem';
import TableList from '../../components/TableList/TableList';
import PackageForm, { PackageValues } from './PackageForm';

interface PackagesTableProps {
  packages: PackageModel[];
  // eslint-disable-next-line no-unused-vars
  setPackages(value: PackageModel[]): void;
  selectedDeliveryOrder: ExtendedDeliveryOrder | null;
}

const mapStateToProps = (state: RootState) => ({
  customers: state.customers,
  deliveryOrders: state.deliveryOrders,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  deleteCSVLine: (packageId: string) => dispatch(deleteCSVLine(packageId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = PackagesTableProps & PropsFromRedux;

function PackagesTable({
  packages,
  setPackages,
  selectedDeliveryOrder,
  customers,
  deliveryOrders,
  deleteCSVLine,
}: PropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<PackageModel | null>(null);
  const isDetail: boolean = selectedDeliveryOrder !== null;

  const editPackage = (packageId: number) => {
    const selectedPackage = [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)].find(
      (packageObject: PackageModel) => packageObject.packageId === packageId,
    );
    setSelectedPackage(selectedPackage!);
    setOpen(true);
  };

  const removePackage = (packageId: number) => {
    const newPackages = packages.filter((packageObject: PackageModel) => packageObject.packageId !== packageId);
    setPackages(newPackages);
    deleteCSVLine(packageId.toString());
  };

  const submitPackage = (values: PackageValues) => {
    const editedPackages = packages.map((item: PackageModel) => {
      if (item.packageId !== selectedPackage?.packageId) {
        return item;
      }

      return {
        ...values,
        packageId: selectedPackage?.packageId,
      };
    });

    setPackages(editedPackages);
    setOpen(false);
  };

  const packagesList: PackageModel[] = isDetail
    ? createPackagesList(selectedDeliveryOrder!)
    : [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)];

  return (
    <div className="packages-table">
      <TableList
        head={['Id', 'Name', 'Address', 'Number of packages', 'Size of packages', 'Status', '']}
        edit={!isDetail}
        onEdit={editPackage}
        delete={!isDetail}
        onDelete={removePackage}
        data={packagesList.map((packageObject: PackageModel) => {
          const customer = customers.customers?.find((c) => c.customerId === packageObject.customerId);

          return [
            packageObject.packageId,
            customer?.firstName || packageObject.firstName || '-',
            customer?.location?.streetAddress || packageObject.streetAddress || (
              <WarningOutlinedIcon color="secondary" />
            ),
            packageObject.packagesAmount,
            packageObject.packagesSize !== 'Custom' ? packageObject.packagesSize : packageObject.customSize,
            packageObject.packagesStatus,
          ];
        })}
      />
      <Dialog open={open} onClose={() => setOpen(false)} className="package-dialog">
        <DialogTitle className="package-dialog-title">
          <GridItem xs={12}>Edit package</GridItem>
        </DialogTitle>
        <DialogContent className="package-dialog-content">
          <PackageForm
            customers={customers}
            submitPackage={submitPackage}
            selectedPackage={selectedPackage!}
            closeDialog={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connector(PackagesTable);

import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/couriers.actions';
import * as toastActions from '../../store/actions/toast.actions';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT = 2;

function VehiclesList({
  loading,
  courier,
  onDeleteVehicle,
  deletingVehicle,
  setToast,
  deleteVehicleSuccess,
  fetchCouriers,
}) {
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [submit, setSubmit] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (deletingVehicle) {
      setSubmit(true);
    }
  }, [deletingVehicle]);

  useEffect(() => {
    if (submit && !deletingVehicle) {
      setSubmit(false);
      if (deleteVehicleSuccess) {
        fetchCouriers();
        setToast('Vehicle successfully deleted', 'success');
        history.push('/couriers'); //TODO update state propery and redirect to update
      } else {
        setToast('Vehicle could not be deleted', 'danger');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingVehicle]);

  const handleCreateVehicle = () =>
    history.push({ pathname: `/couriers/${courier.courierId}/vehicle/create`, state: { editMode: false } });

  const handleUpdateVehicle = (vehicleId) =>
    history.push({ pathname: `/couriers/${courier.courierId}/vehicle/${vehicleId}/update`, state: { editMode: true } });

  const handleDeleteVehicle = (vehicleId) => setVehicleToDelete(vehicleId);

  const deleteVehicle = () => {
    onDeleteVehicle(courier.courierId, vehicleToDelete);
    setVehicleToDelete(null);
  };

  const handleCloseDialog = () => setVehicleToDelete(null);

  let vehicles = null;
  if (courier && courier.vehicle.length > 0) {
    const options = courier.vehicle.map((vehicle) => {
      return [
        vehicle.id,
        vehicle.vehicleType,
        vehicle.licensePlate || '-',
        vehicle.model || '-',
        vehicle.color || '-',
        vehicle.capacity || '-',
      ];
    });

    vehicles = (
      <TableList
        title={false}
        head={['Id', 'Type', 'License plate', 'Model', 'Color', 'Capacity']}
        onEdit={handleUpdateVehicle}
        onDelete={handleDeleteVehicle}
        edit
        delete
        data={options}
        pagination={
          <Pagination
            totalRecords={options?.length || 0}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={1}
            onPageChanged={function () {}}
          />
        }
      />
    );
  } else if (loading) {
    vehicles = <Spinner />;
  } else {
    vehicles = <div className="no-vehicles">Please add their first vehicle!</div>;
  }

  return (
    <Fragment>
      <Card profilev>
        <CardBody profile className="courier-vehicle-list">
          <h4>{courier?.firstName}&#39;s vehicles</h4>
          {vehicles}
          <CustomDialog
            title={'Confirm'}
            description={'¿Are you sure you want to delete this vehicle?'}
            open={vehicleToDelete != null}
            onConfirmation={deleteVehicle}
            handleClose={handleCloseDialog}
            okButtonText={'Yes'}
            cancelButtonText={'Cancel'}
          />
        </CardBody>
        <CardFooter>
          <Button
            className="submit-button"
            color="primary"
            disabled={courier?.vehicle && courier?.vehicle.length === 2}
            onClick={handleCreateVehicle}
          >
            ADD VEHICLE
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    courier: state.couriers.courier,
    deletingVehicle: state.couriers.deletingVehicle,
    deleteVehicleSuccess: state.couriers.deleteVehicleSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDeleteVehicle: (courierId, vehicleId) => dispatch(actions.deleteVehicle(courierId, vehicleId)),
  setToast: (message, messageType) => dispatch(toastActions.setToast(message, messageType)),
  fetchCouriers: () => dispatch(actions.fetchCouriers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesList);

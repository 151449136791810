import {
  CSVLine,
  DeliveryOrder,
  ExtendedDeliveryOrder,
  ExtendedPackageGroup,
  Package,
  PackageGroup,
  PackageModel,
} from '../store/config/types/deliveryOrders.types';

// Package statuses: ORDER_SUBMITTED, AWAITING_PICKUP, ON_THE_WAY, FAILED, DELIVERED
// Delivery Order item statuses: ORDER_SUBMITTED, SCHEDULED, IN_TRANSIT, DELIVERED, NEEDS_ATTENTION

function getPackageGroupStatus(packages: Package[]): string {
  const packagesStatuses: string[] = packages.map((packageItem: Package) => packageItem.packageStatus);

  if (packagesStatuses.every((status: string) => status === 'ORDER_SUBMITTED')) return 'Order submitted';
  if (packagesStatuses.every((status: string) => status === 'AWAITING_PICKUP')) return 'Scheduled';
  if (packagesStatuses.every((status: string) => status === 'DELIVERED')) return 'Delivered';
  if (packagesStatuses.find((status: string) => status === 'FAILED')) return 'Needs attention';

  return 'In transit';
}

export function extendDeliveryOrder(deliveryOrder: DeliveryOrder): ExtendedDeliveryOrder {
  let packagesAmount: number = 0;

  deliveryOrder.packageGroups.forEach((packageGroup: PackageGroup) => {
    packagesAmount += packageGroup.packages?.length || 0;
  });

  const recipientsCount: number = deliveryOrder.packageGroups.length;

  const extendedDeliveryOrder: ExtendedDeliveryOrder = {
    ...deliveryOrder,
    packageGroups: deliveryOrder.packageGroups.map((packageGroup: PackageGroup) => {
      const extendedPackageGroup: ExtendedPackageGroup = {
        ...packageGroup,
        packageGroupStatus: getPackageGroupStatus(packageGroup.packages),
      };
      return extendedPackageGroup;
    }),
    packagesAmount,
    numberOfRecipients: recipientsCount,
  };

  return extendedDeliveryOrder;
}

export function createPackagesList(deliveryOrder: ExtendedDeliveryOrder): PackageModel[] {
  const packages: PackageModel[] = deliveryOrder.packageGroups.map((packageGroup: ExtendedPackageGroup) => {
    const packageModel: PackageModel = {
      customerId: packageGroup.customerId,
      deliveryInstructions: packageGroup.instructions,
      packageId: packageGroup.packageGroupId,
      packagesAmount: packageGroup.packages.length,
      packagesSize: packageGroup.packages[0].size.charAt(0),
      packagesStatus: packageGroup.packageGroupStatus,
    };

    return packageModel;
  });

  return packages;
}

export function transformCSVLines(csvLines: CSVLine[] | null): PackageModel[] {
  if (csvLines === null) {
    return [];
  }

  const parsedLines: PackageModel[] = csvLines.map((csvLine: CSVLine) => {
    return {
      ...csvLine,
      packagesAmount: Number(csvLine.count),
      packagesSize: csvLine.size,
    };
  });

  return parsedLines;
}

export function formatPackagesStatus(packagesStatus: string): string {
  const prettyStatus: string = packagesStatus.toLowerCase().replaceAll('_', ' ');
  return prettyStatus.charAt(0).toLocaleUpperCase() + prettyStatus.slice(1);
}

import PropTypes from 'prop-types';
import { withStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import customInputStyle from '../../../assets/jss/customInputStyle';

function CustomAutocomplete({ value, labelText, id, inputProps }) {
  function handleChange(event, value) {
    if (value && value.id) {
      inputProps.setValue(value.id);
    } else {
      inputProps.setValue(0);
    }
  }

  return (
    <Autocomplete
      fullWidth
      clearOnEscape
      getOptionLabel={(option) => {
        if (option.title) return option.title;
        return inputProps.values.find((o) => o.id === option)?.title || '';
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      id={id}
      renderInput={(params) => <TextField {...params} label={labelText} />}
      options={inputProps.values}
      style={{ marginBottom: 8 }}
      onChange={handleChange}
      value={value}
    />
  );
}

CustomAutocomplete.propTypes = {
  labelText: PropTypes.node,
  id: PropTypes.string,
};

export default withStyles(customInputStyle)(CustomAutocomplete);

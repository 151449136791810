import { DeliveredIcon, PickupIcon, TransitIcon, BoxIcon } from '../assets/images';

export interface TitleSection {
  image: string;
  amount: number;
  title: string;
  color: string;
  status: string;
}

export const dataTitle: TitleSection[] = [
  {
    image: BoxIcon,
    amount: 0,
    title: 'Deliveries',
    color: 'title-color-one',
    status: 'ALL',
  },
  {
    image: PickupIcon,
    amount: 0,
    title: 'Scheduled',
    color: 'title-color-two',
    status: 'AWAITING_PICKUP',
  },
  {
    image: TransitIcon,
    amount: 0,
    title: 'In transit',
    color: 'title-color-three',
    status: 'ON_THE_WAY',
  },
  {
    image: DeliveredIcon,
    amount: 0,
    title: 'Delivered',
    color: 'title-color-four',
    status: 'DELIVERED',
  },
];

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { Dialog, DialogContent, Paper } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/white_loader.json';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import CustomToggle from '../../components/CustomToggle/CustomToggle';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import Spinner from '../../components/Spinner/Spinner';
import AutocompleteField from '../../components/SpecialInput/AutocompleteField';
import { setToast } from '../../store/actions/toast.actions';
import {
  createDeliveryOrder,
  fetchDeliveryOrders,
  clearDeliveryOrdersState,
} from '../../store/actions/deliveryOrders.actions';
import { RootState } from '../../store/config/types';
import {
  CustomerRequest,
  DeliveryOrder,
  DeliveryOrderRequest,
  ExtendedDeliveryOrder,
  PackageModel,
  PackageRequest,
} from '../../store/config/types/deliveryOrders.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchCustomers } from '../../store/actions/customers.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import schemas from '../../utils/schemas';
import useIsMobile from '../../utils/useIsMobile';
import { transformCSVLines } from '../../utils/deliveryOrders.helpers';
import WarehouseForm from '../Warehouses/WarehouseForm';
import PackageForm, { PackageValues } from './PackageForm';
import PackagesTable from './PackagesTable';
import CSVDialog from './CSVDialog';

interface DeliveryOrderParams {
  deliveryOrderId?: string;
}

interface DeliveryOrderValues {
  date: Date | string;
  warehouseId: number;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  deliveryOrders: state.deliveryOrders,
  customers: state.customers,
  warehouses: state.warehouses,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateDeliveryOrder: (deliveryOrderRequest: DeliveryOrderRequest) =>
    dispatch(createDeliveryOrder(deliveryOrderRequest)),
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  clearDeliveryOrdersState: () => dispatch(clearDeliveryOrdersState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrderForm({
  auth,
  deliveryOrders,
  customers,
  warehouses,
  onCreateDeliveryOrder,
  fetchDeliveryOrders,
  fetchCustomers,
  fetchWarehouses,
  setToast,
  clearDeliveryOrdersState,
}: PropsFromRedux) {
  const history: History = useHistory();
  const isMobile: boolean = useIsMobile();
  const { deliveryOrderId } = useParams<DeliveryOrderParams>();
  const editMode: boolean = Boolean(deliveryOrderId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState<ExtendedDeliveryOrder | null>(null);
  const [packages, setPackages] = useState<PackageModel[]>([]);
  const [sameDay, setSameDay] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [warehouseOpen, setWarehouseOpen] = useState<boolean>(false);
  const [justCreatedWarehouse, setJustCreatedWarehouse] = useState<boolean>(false);

  useEffect(() => {
    if (editMode && selectedDeliveryOrder === null && deliveryOrders.deliveryOrders && deliveryOrderId) {
      const editedDeliveryOrder = deliveryOrders.deliveryOrders.filter(
        (w: DeliveryOrder) => w.deliveryOrderId === Number(deliveryOrderId),
      )[0];
      setSelectedDeliveryOrder(editedDeliveryOrder);
    }
  }, [editMode, selectedDeliveryOrder, deliveryOrders.deliveryOrders, deliveryOrderId, setSelectedDeliveryOrder]);

  useEffect(() => {
    if (!deliveryOrders.deliveryOrders && auth.account) {
      fetchDeliveryOrders();
    }
  }, [deliveryOrders.deliveryOrders, auth.account, fetchDeliveryOrders]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage &&
      auth.account
    ) {
      fetchWarehouses(auth.account?.shipperId || 0);
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
    auth.account,
  ]);

  useEffect(() => {
    if (
      !customers.loadingCustomers &&
      !customers.customers &&
      !customers.customersFetched &&
      !customers.customersErrorMessage
    ) {
      fetchCustomers();
    }
  }, [
    fetchCustomers,
    customers.loadingCustomers,
    customers.customers,
    customers.customersFetched,
    customers.customersErrorMessage,
  ]);

  useEffect(() => {
    if (submitting) {
      if (deliveryOrders.createDeliveryOrderSuccess) {
        setSubmitting(false);
        setToast(
          `Delivery Order successfully ${deliveryOrders.createDeliveryOrderSuccess ? 'created' : 'updated'}`,
          'success',
        );
        clearDeliveryOrdersState();
        history.push('/delivery-orders');
      } else if (deliveryOrders.createDeliveryOrderErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${
            deliveryOrders.createDeliveryOrderErrorMessage ? 'creating' : 'updating'
          } Delivery Order`,
          'danger',
        );
        clearDeliveryOrdersState();
      }
    }
  }, [
    submitting,
    deliveryOrders.createDeliveryOrderSuccess,
    deliveryOrders.createDeliveryOrderErrorMessage,
    history,
    setSubmitting,
    setToast,
    clearDeliveryOrdersState,
  ]);

  const importCSV = () => setOpen(true);

  const createNewWarehouse = () => setWarehouseOpen(true);

  const buildCustomer = (packageObject: PackageModel): CustomerRequest => {
    return {
      firstName: packageObject.firstName,
      lastName: packageObject.lastName,
      specialInstructions: packageObject.deliveryInstructions,
      location: {
        city: packageObject.city,
        latitude: packageObject.latitude?.toString(),
        longitude: packageObject.longitude?.toString(),
        streetAddress: packageObject.streetAddress,
      },
    };
  };

  const submitForm = (values: DeliveryOrderValues) => {
    if (auth.account) {
      const allPackages = [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)];

      const packageRequests: PackageRequest[] = allPackages.map((packageObject: PackageModel) => {
        return {
          customerId: packageObject.customerId,
          customer: packageObject.customerId ? undefined : buildCustomer(packageObject),
          instructions: packageObject.deliveryInstructions!,
          count: packageObject.packagesAmount!,
          size: packageObject.packagesSize!,
        };
      });

      const deliveryOrderRequest: DeliveryOrderRequest = {
        shipperId: auth.account.shipperId!,
        warehouseId: values.warehouseId,
        deliveryExpectedAt: moment(values.date).format('l LT'),
        packageGroups: packageRequests,
      };

      setSubmitting(true);

      if (editMode) {
        // deliveryOrderRequest.deliveryOrderId = selectedDeliveryOrder?.deliveryOrderId;
      } else {
        onCreateDeliveryOrder(deliveryOrderRequest);
      }
    }
  };

  const handleGoBack = () => history.push('/delivery-orders');

  const submitPackage = (values: PackageValues, formikHelpers: FormikHelpers<PackageValues>) => {
    const newPackage: PackageModel = {
      ...values,
      packageId: packages.length + 1,
    };

    setPackages([...packages, newPackage]);
    formikHelpers.resetForm();
  };

  if (editMode && !selectedDeliveryOrder) {
    return <Spinner />;
  }

  const selectedWarehouse =
    selectedDeliveryOrder &&
    warehouses.warehouses &&
    warehouses.warehouses.find((warehouse: Warehouse) => warehouse.warehouseId === selectedDeliveryOrder.warehouseId);

  const selectedDate = moment.utc(selectedDeliveryOrder?.createdAt).format('MM/DD/YYYY');

  const closeWarehouseModal = () => setWarehouseOpen(false);

  const warehouseModalSuccess = () => {
    setJustCreatedWarehouse(true);
    closeWarehouseModal();
  };

  return (
    <div className="delivery-order-form">
      <Card profile>
        <CardHeader color="primary" className="delivery-order-header">
          <h4>
            {editMode && (
              <span className="go-back" onClick={() => history.goBack()}>
                <ChevronLeft /> Go back
              </span>
            )}
            <span>
              {editMode && selectedDeliveryOrder
                ? `Delivery Order ${selectedDeliveryOrder.deliveryOrderId}`
                : 'New Delivery Order'}
            </span>
            {editMode && <span />}
          </h4>
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              date: selectedDeliveryOrder
                ? selectedDeliveryOrder.deliveryExpectedAt!
                : moment.utc().format('YYYY-MM-DD'),
              warehouseId:
                justCreatedWarehouse && warehouses.createdWarehouse
                  ? warehouses.createdWarehouse.warehouseId!
                  : selectedDeliveryOrder
                  ? selectedDeliveryOrder.warehouseId!
                  : warehouses.warehouses
                  ? warehouses.warehouses[0]?.warehouseId!
                  : 0,
            }}
            validationSchema={schemas.DeliveryOrderSchema}
            onSubmit={submitForm}
            // enableReinitialize
          >
            {({ values }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} lg={6}>
                    {warehouses.warehouses && (
                      <div className="warehouse-row">
                        {editMode && selectedWarehouse ? (
                          <span className="info">
                            <b>Warehouse: </b>
                            {selectedWarehouse?.name}
                          </span>
                        ) : (
                          <AutocompleteField
                            placeholder="Warehouse"
                            name="warehouseId"
                            values={warehouses.warehouses.map((warehouse: Warehouse) => ({
                              id: warehouse.warehouseId,
                              title: warehouse.name,
                            }))}
                            value={values.warehouseId}
                          />
                        )}
                        {!editMode && (
                          <span className="link" onClick={createNewWarehouse}>
                            {isMobile ? 'New' : 'Create new warehouse'}
                          </span>
                        )}
                      </div>
                    )}
                  </GridItem>
                  {editMode ? (
                    <GridItem xs={12} className="left-align">
                      <span className="info">
                        <b>Date: </b> {selectedDate}
                      </span>
                    </GridItem>
                  ) : (
                    <CustomToggle value={sameDay} setValue={setSameDay} />
                  )}
                  {!sameDay && !editMode && (
                    <GridItem xs={12} lg={3}>
                      <InputField name="date" type="date" formControlClassName="custom-input" />
                    </GridItem>
                  )}
                  {!editMode && (
                    <GridItem xs={12} sm={12}>
                      <div className="btn-group">
                        <Button
                          type="submit"
                          className="submit-button"
                          color="primary"
                          disabled={deliveryOrders.creatingDeliveryOrder}
                        >
                          {deliveryOrders.creatingDeliveryOrder ? (
                            <Lottie animationData={loadingAnimation} className="loading-button" loop={true} />
                          ) : (
                            'Create Delivery Order'
                          )}
                        </Button>
                        <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                          Cancel
                        </Button>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </Form>
            )}
          </Formik>
          <GridContainer>
            {!editMode && (
              <>
                <GridItem xs={12}>
                  <div className="import-subtitle">
                    <h3 className="section-title">Import your packages or add manually</h3>
                    <Button color="primary" onClick={importCSV}>
                      Import delivery items CSV
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <Paper className="package-form">
                    <PackageForm customers={customers} submitPackage={submitPackage} />
                  </Paper>
                </GridItem>
              </>
            )}
            <GridItem xs={12}>
              <h3 className="section-title text">Delivery items</h3>
            </GridItem>
          </GridContainer>
          <PackagesTable packages={packages} setPackages={setPackages} selectedDeliveryOrder={selectedDeliveryOrder} />
        </CardBody>
      </Card>
      <CSVDialog open={open} setOpen={setOpen} />
      <Dialog open={warehouseOpen} onClose={closeWarehouseModal}>
        <DialogContent>
          <WarehouseForm fromModal closeModal={closeWarehouseModal} submitModal={warehouseModalSuccess} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connector(DeliveryOrderForm);

import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import tableStyle from '../../assets/jss/tableStyle';
import { createCourier, updateCourier, setCourier, clearCouriersState } from '../../store/actions/couriers.actions';
import * as actions from '../../store/actions/toast.actions';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../../components/CustomInput/CustomInput';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import VehiclesList from './VehiclesList';

const mapStateToProps = (state) => ({
  couriers: state.couriers,
  courier: state.couriers.courier,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateCourier: (courier, courierPhoto) => dispatch(createCourier(courier, courierPhoto)),
  onUpdateCourier: (courierId, courier, courierPhoto) => dispatch(updateCourier(courierId, courier, courierPhoto)),
  unsetCourier: () => dispatch(setCourier()),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
  clearCouriersState: () => dispatch(clearCouriersState()),
});

const CourierForm = ({
  onCreateCourier,
  onUpdateCourier,
  unsetCourier,
  setToast,
  clearCouriersState,
  couriers,
  courier,
  ...props
}) => {
  const { classes } = props;
  const { courierId } = useParams();
  const state = useLocation().state;
  const editMode = state?.editMode || Number(courierId) > 0;
  const history = useHistory();
  const [courierPhoto, setCourierPhoto] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstName: courier?.firstName || '',
    lastName: courier?.lastName || '',
    phoneNumber: courier?.phoneNumber || '',
    email: courier?.email || '',
    homeAddress: courier?.homeAddress || '',
  });

  useEffect(() => {
    if (!couriers.couriers || (!courier && Number(courierId) > 0) || courier?.courierId !== Number(courierId)) {
      history.push('/couriers');
    }
  });

  useEffect(() => {
    if (!editMode) {
      setFormData({ ...formData, password: '' });
    }
    if (courier?.photoUrl) {
      setCourierPhoto(couriers.courier.photoUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitting) {
      if (couriers.createCourierSuccess || couriers.updateCourierSuccess) {
        setSubmitting(false);
        unsetCourier();
        setToast(`Courier successfully ${couriers.createCourierSuccess ? 'created' : 'updated'}`, 'success');
        clearCouriersState();
        history.push({ pathname: state?.from || '/couriers', state: { createdCourier: true } });
      } else if (couriers.createCourierErrorMessage || couriers.updateCourierErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${couriers.createCourierErrorMessage ? 'creating' : 'updating'} courier`,
          'danger',
        );
        clearCouriersState();
      }
    }
  }, [
    submitting,
    couriers.createCourierSuccess,
    couriers.updateCourierSuccess,
    couriers.createCourierErrorMessage,
    couriers.updateCourierErrorMessage,
    history,
    setSubmitting,
    setToast,
    state?.from,
    unsetCourier,
    clearCouriersState,
  ]);

  const inputChangedHandler = (event, inputIdentifier) => {
    setFormData({ ...formData, [inputIdentifier]: event.target.value });
  };

  const onFileChange = (event) => {
    setCourierPhoto(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (editMode) {
      await onUpdateCourier(Number(courier.courierId), formData, courierPhoto);
    } else {
      await onCreateCourier(formData, courierPhoto);
    }
  };

  return (
    <div className="courier-form">
      <Card profile>
        <CardHeader color="primary" className="card-header">
          {editMode && courier ? <h4>{`${courier.firstName} ${courier.lastName}`}</h4> : <h4>New courier</h4>}
        </CardHeader>
        <CardBody profile>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <div className={classes.imageFormContainer}>
                <div className={classes.profilePicContainerLarge}>
                  <img
                    className={classes.profilePicLarge}
                    src={
                      courierPhoto
                        ? URL.createObjectURL(courierPhoto)
                        : couriers?.courier?.photoUrl
                        ? couriers.courier.photoUrl
                        : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                    }
                  />
                  {courierPhoto ? <span className={classes.updatedImage}>Unsaved changes</span> : null}
                </div>
                <Button className={classes.imageButton} variant="contained" component="label">
                  Upload File
                  <input accept="image/*" type="file" hidden onChange={onFileChange} />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="First name"
                id="firstName"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.firstName,
                  onChange: (event) => inputChangedHandler(event, 'firstName'),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Last name"
                id="lastName"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.lastName,
                  onChange: (event) => inputChangedHandler(event, 'lastName'),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Phone number"
                id="phoneNumber"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.phoneNumber,
                  onChange: (event) => inputChangedHandler(event, 'phoneNumber'),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.email,
                  onChange: (event) => inputChangedHandler(event, 'email'),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Home address"
                id="homeAddress"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: formData.homeAddress,
                  onChange: (event) => inputChangedHandler(event, 'homeAddress'),
                }}
              />
            </GridItem>
            {!editMode ? (
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: formData.password,
                    type: 'password',
                    onChange: (event) => inputChangedHandler(event, 'password'),
                  }}
                />
              </GridItem>
            ) : (
              <GridItem xs={12} sm={6}></GridItem>
            )}
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            className="submit-button"
            color="primary"
            disabled={
              formData.firstName === '' ||
              formData.lastName === '' ||
              formData.phoneNumber === '' ||
              formData.password === '' ||
              formData.homeAddress === ''
            }
            onClick={handleSubmit}
          >
            {editMode ? 'UPDATE' : 'CREATE'} COURIER
          </Button>
        </CardFooter>
      </Card>
      {editMode ? <VehiclesList /> : null}
    </div>
  );
};

export default withStyles(tableStyle)(connect(mapStateToProps, mapDispatchToProps)(CourierForm));

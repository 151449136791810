import { CSVLine, DeliveryOrder, DeliveryOrderRequest } from '../store/config/types/deliveryOrders.types';
import axios from './axios';

async function fetchDeliveryOrders(): Promise<DeliveryOrder[] | undefined> {
  const res = await axios.get('/delivery_orders');
  return res.data.data;
}

async function createDeliveryOrder(
  createdeliveryOrderRequest: DeliveryOrderRequest,
): Promise<DeliveryOrder | undefined> {
  const res = await axios.post('/delivery_orders/package_groups', createdeliveryOrderRequest);
  return res.data.data;
}

async function parseCSVToPackages(formData: FormData): Promise<CSVLine[] | undefined> {
  const res = await axios.post('/delivery_orders/csv', formData);
  return res.data.data;
}

export const deliveryOrdersService = { fetchDeliveryOrders, createDeliveryOrder, parseCSVToPackages };
